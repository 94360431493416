.contact{
	margin-bottom: 1px;
	// display: none;

	position: fixed;
	top: 0;
	left: 0;
	height: 100vh;
	width: 100vw;
	opacity: 0;
	visibility: hidden;
	transform: scale(1.2);
	transition: all .5s ease;
	z-index: 1;
	background-color: white;

	&.showing{
		opacity: 1;
		visibility: visible;
		transform: scale(1);
	}

	.contact_close{
		position: absolute;
		top: @margin;
		right: @margin;
		height: 60px;
		width: 60px;
		background: black;
		border-radius: 50%;
		cursor: pointer;

		&:before,
		&:after{
			content: '';
			position: absolute;
			top: 50%;
			left: 50%;
			height: 1px;
			width: 30px;
			margin: -1px 0 0 -15px;
			background: white;
			z-index: 1;
		}

		&:before{
			transform: rotate(-45deg);
		}

		&:after{
			transform: rotate(45deg);
		}

	}

	.contact_interior{
		position: absolute;
		top: 160px; 
		right: 20px;

		&:before{
			content: '';
			position: absolute;
			top: -35px;
			right: 0;
			// width: @width_box_desktop;
			width: ~'calc( @{width_box_desktop} - 80px )';
			height: 1px;
			background-color: black;
		}


		p{
			line-height: 30px;
			font-size: 20px;
			text-align: right;

			@media @mobile-max {
				font-size: 12px;				
			}

			a{
				color: black;
				text-decoration: none;
				border-bottom: 1px dotted black;
			}
		}
	}


	.contact_haerz{
		height: 50px;
		width: 50px;
		position: absolute;
		bottom: 30px;
		right: 20px;
		background-image: url(../img/haerz2.png);
		background-size: contain;
		background-position: center;
		background-repeat: no-repeat;
		cursor: pointer;
	}
}