.item_info{
	// top: ~'calc(35vh + 70px)';
	// top: 35vh;
	width: 100vw;
	// position: relative;
	// float: left;

	background-color: white;
	// z-index: 10;

	padding-top: 35vh;



	@media @tablet-min{
		align-self: flex-end;
		// top: auto;
		padding-top: 0;
		// padding-bottom: 0;
		// background-color: transparent;
		// position: absolute;
		padding-bottom: 70px;
		// top: ~'calc(35vh + 70px)';
	}

	@media @desktop-min{
		// top: ~'calc(50vh + 70px)';
	}

	

	// display: none;

	.main_item_interior.showing &{
		display: block;
	}

	.info_interior{

		max-width: 1024px;
		width: 100%;
		margin: 0 auto;
		padding: @margin @margin @margin * 2;
		
		// position: fixed;
		// top: 0;
		// left: 0;
		position: relative;
		overflow: hidden;

		padding-bottom: 100px;

		@media @tablet-min{
			padding-bottom: @margin;
		}

		&:before{
			content: 'WEIHNACHTSSTADT BERN \a© BERNERWEIHNACHTSMÄRKTE.CH 2017';
			white-space: pre;
			position: absolute;
			bottom: @margin;
			width: 100vw;
			left: 0;
			color: white;
			z-index: 2;	
			font-family: RobotoCondensed-Regular;
			text-align: center;
		}

		&:after{
			content: '';
			position: absolute;
			left: 0;
			width: 100vw;
			bottom: 0;
			height: 90px;
			z-index: 1;
			background-color: @color-brand;
		}

		&:before,
		&:after{
			@media @tablet-min{
				display: none;
			}
		}

		hr{
			border-color: @color-brand;
		}

		h1{
			font-family: abrilfatface-regular;
			color: @color-brand;

			font-size: 23px;

			@media @mobile-min{
				font-size: 32px;							
			}
		}

		p{

			font-family: sourceserifpro-regular;
			// font-size: 18px;
			// line-height: 28px;
			margin-bottom: 0;
			
			line-height: 25px;
			font-size: 15px;

			@media @mobile-min{
				line-height: 27px;
				font-size: 17px;
			}

			b, strong{
				font-family: sourceserifpro-bold;
			}
		}

		.info_date{
			margin-top: @margin;
			*{

				// font-family: sourceserifpro-bold;
				color: @color-brand;
			}
		}

		// .market_logo{

		// 	width: 100%;
		// 	// height: 150px;
		// 	background-size: contain;
		// 	background-position: center;
		// 	background-repeat: no-repeat;
		// 	margin-bottom: @margin;

		// 	&:before{
		// 		content: '';
		// 		padding-bottom: 40%;
		// 		display: block;
		// 	}
		// }

		// .ml_01{
		// 	background-image: url(../img/logo_01.png);
		// }
		// .ml_02{
		// 	background-image: url(../img/logo_02.png);
		// }
		// .ml_03{
		// 	background-image: url(../img/logo_03.png);
		// }
		// .ml_04{
		// 	background-image: url(../img/logo_04.png);
		// }
		// .ml_05{
		// 	background-image: url(../img/logo_05.png);
		// }
		// .ml_06{
		// 	background-image: url(../img/logo_06.png);
		// }
		// float: left;
		// height: 100vh;
		// overflow: scroll;
		// padding: @margin * 2 25vw;

		// display: none;


		// visibility: hidden;
		// opacity: 0;

		// &:before{
		// 	content: '';
		// 	// width: 100%;
		// 	width: ~'calc(100% - (@{margin} * 4))';
		// 	height: 1px;
		// 	background-color: black;
		// 	// top: -@margin * 1.5;
		// 	top: @margin * 2;
		// 	left: @margin * 2;
		// 	position: absolute;
		// }		

		// .info_close{
		// 	position: absolute;
		// 	top: @margin;
		// 	right: @margin;
			
		// }

		// .info_text{
		// 	position: relative;
		// 	float: left;
		// 	width: 100%;
		// 	padding: 0 @margin @margin;
		// 	color: black;
		// 	font-size: 18px;
		// 	line-height: 28px;

		// 	@media @mobile-min{
		// 		width: 50%;
		// 	}

		// 	@media @desktop-min{
		// 		width: 25%;
		// 	}

		// 	h2{
		// 		font-family: rubikregular;
		// 		padding-bottom: @margin;
		// 		border-bottom: 1px solid black;
		// 		margin-bottom: @margin;
		// 		font-size: 25px;
		// 	}

		// 	a{
		// 		text-decoration: none !important;
		// 		border-bottom: 1px dotted black;
		// 		color: black;
		// 	}

		// }

		// .info_image{
		// 	position: relative;
		// 	float: left;
		// 	// width: 100%;
		// 	width: ~'calc(100% - (@{margin} * 2))';
		// 	// max-width: 
		// 	margin: @margin * 2 @margin;
		// 	// margin: @margin 0 @margin * 2 0;
		// 	background-size: cover;
		// 	background-repeat: no-repeat;


		// 	&:before{
		// 		padding-bottom: 80%;
		// 		content: '';
		// 		display: block;
		// 	}

		// 	&:after{
		// 		content: '';
		// 		width: 100%;
		// 		height: 1px;
		// 		background-color: black;
		// 		top: -@margin * 1.5;
		// 		left: 0;
		// 		position: absolute;
		// 	}
		// }
	}
}