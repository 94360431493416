@font-face {
  font-family: "fonticons";
  src: url('../../assets/fonts/fonticons.eot');
  src: url('../../assets/fonts/fonticons.eot?#iefix') format('eot'),
    url('../../assets/fonts/fonticons.woff') format('woff'),
    url('../../assets/fonts/fonticons.ttf') format('truetype'),
    url('../../assets/fonts/fonticons.svg#fonticons') format('svg');
  font-weight: normal;
  font-style: normal;
}

.font-styling(){
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-family: "fonticons";
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  speak: none;
  text-align: center;
  text-decoration: none;
  text-transform: none;
  vertical-align: top;
  line-height: inherit;
}

[class^="gfx-"], [class*=" gfx-"]{
  &:before, &.after:after{
    .font-styling();
  }
  &:before{margin-right: 0.5em;}
  &.after {
    &:before{display: none;}
    &:after {margin-left: 0.5em;}
  }
}

.gfx(@class, @element: before){
  & when(@element = before){
    &:before{
      .glyphmap();
    }
  }
  
  & when(@element = after){
    &:after{
      .glyphmap();
    }
  }

  .glyphmap(){
    .font-styling();
    & when (@class = logo_waisenhausplatz-01){ content: "\E001" }
    
  }
}

.gfx-logo_waisenhausplatz-01:before { content: "\E001" }


.after.gfx-logo_waisenhausplatz-01:after { content: "\E001" }