html,
body{
	position: relative;
	color: @color-copy;
	font-family: rubiklight; 
	font-weight: 400;
	font-size: @base-font-size;
	line-height: @base-line-height;	
	// background-color: #3F00FF;
	// background-color: #eee;
	color: #444;
}

body{
	padding: 0 20px;
}

.main{
	// width: @width_box_desktop;
	// position: absolute;
	// height: 100vh;
	// width: 100vw;
	// top: 0;
	// left: 0;

	// min-height: 100%;
	// height: @height_box_desktop;
	// margin: @height_space_desktop auto;
	// border: 20px solid white;
	// border-left: 1px solid #aaa;
	// overflow: scroll;
	// -webkit-overflow-scrolling: touch;
	// padding: 0 20px;
	// background-color: #3F00FF;
	// color: white;

	// @media @mobile-max {
	// 	// width: @width_box_mobile;
	// 	// height: @height_box_mobile;
	// 	// margin: @height_space_mobile auto;
		
	// }

	// .main_interior{
	// 	overflow: hidden;
	// }
}

footer{
	background-color: @color-brand;
	width: 100%;
	color: white;
	transform: translateY(100%);
	transition: all .5s ease;
	height: 20px; 
	line-height: 20px;
	font-family: RobotoCondensed-Regular;
	position: relative;
	float: left;
	display: none;
	
	@media @tablet-min{
		display: block;
		position: fixed;
		bottom: 0;
		left: 0;
		line-height: 70px;
		height: 70px; 
	}

	.showNav &{
		@media @tablet-min{
			transform: translateY(0);			
		}
	}

	// {		

		.right{
			@media @mobile-min{
				text-align: right;
			}
		}
		// color: @color-brand;
	// }

}

.section{
	position: relative;
	float: left;
	width: 100%;
	min-height: @height_box_desktop;
	padding: 4em 0 8em;

	@media @mobile-max {
		padding: 2em 0;
		min-height: @height_box_mobile;
	}	
}

.subsection{
	padding: 2em 0;

	@media @mobile-max {
		padding: 1em 0;
	}	
}