.logo{
	position: fixed;
	top: @margin;
	left: @margin;
	z-index: 1;
	padding-bottom: @margin;
	// padding-bott

	.showing &{
		@media @desktop-max{
			width: ~'calc(100% - (@{margin} * 2))';
		}
	}

	.logo_type{
		height: 100vh;
		width: 100vw;
		max-height: 175px;
		max-width: 175px;
		background-size: cover;

		font-family: rubikmedium; 
		background-color: white;
		color: black;
		padding: @margin;
		font-size: 4.5vh;
		line-height: 6.3vh;
		cursor: pointer;

		@media screen and (max-width: 800px) and (min-height: 800px){
			// max-height: 50vh;
			max-height: none;
			height: auto;
		}

		@media @mobile-min{
			max-height: 225px;
			max-width: 225px;
		}

		@media @desktop-min{
			min-height: 18vw;
			min-width: 18vw;		
			font-size: 4.4vw;
			line-height: 5vw;			
		}

		.showing &{
			width: ~'calc(100vw - (@{margin} * 2))';
			height: auto;
			font-size: 2.5vw;
			line-height: 2.7vw;
			min-width: 0;
			max-width: none;
			min-height: 0;
			max-height: none;

			// @media screen and (max-width: 450px){

			// 	font-size: 3vw;
			// 	line-height: 3.2vw;
			// }

			@media @mobile-min{
				font-size: 3vw;
				line-height: 3.2vw;
			}

			@media @tablet-min{
				// font-size: 3.5vw;
				// line-height: 4vw;
			}

			@media @desktop-min{
				// width: 100%;
				width: ~'calc(((100vw - 1024px) / 2))';
				height: ~'calc(((100vw - 1024px) / 2))';


				font-size: ~'calc((100vw - 1024px) / 12)';
				line-height: ~'calc((100vw - 1024px) / 12)';
			}
		}


		// span{
		// 	font-family: robotoBlack;
		// 	font-size: 50px;
		// 	line-height: 50px;
		// 	color: black;
		// }
	}
}

// .logo{
// 	height: 100%;
// 	width: 100%;
// 	position: relative;
// 	padding: 0;

// 	.logo_interior{
// 		position: absolute;
// 		width: 450px;
// 		// background-image: url(../img/m_w5.png);
// 		// background-size: 100% auto;
// 		// background-position: bottom center;
// 		top: 50%;
// 		left: 50%;
// 		margin: -275px 0 0 -225px;
// 		z-index: 1;

// 		&:before{
// 			content: '';
// 			padding-bottom: 100%;
// 			z-index: 2;
// 			position: relative;
// 			display: block;

// 			background-image: url(../img/m_w7.png);
// 			background-size: 100% auto;
// 			background-position: bottom center;

// 			@media @mobile-max {
// 				// background-size: auto 100%;
// 			}
// 		}

// 		&:after{
// 			// content: '';
// 			// position: absolute;
// 			// z-index: 1;
// 			// border-radius: 50%;
// 			// background-color: white;
// 			// top: 50%;
// 			// left: 50%;
// 			// max-height: 276px;
// 			// max-width: 276px;
// 			// height: 0;
// 			// width: 0;
// 			// // margin: -15px 0 0 -138px;
// 			// transform: translateY(~'calc(-50% - 15px)') translateX(-50%);

// 			// transition: all 1.5s ease;
// 			// transition-delay: 1.5s;

// 			@media @mobile-max {
// 				// height: 50/vw;
// 				// width: 50v/w;
// 				// margin-top: ~'calc((-25vw - 15px))';
// 				// margin-left: -25vw;

// 				// margin-top: -50%;
// 				// margin-left: -50%;
// 			}
			

// 		}

// 		@media @mobile-max {
// 			width: 100%;
// 			left: 0;
// 			margin: 0;
// 			transform: translateY(-50%);
// 		}

// 		.pageLoaded &:before{
// 			animation: full_flex 1.5s forwards step-start; 
// 			// animation: full_flex 3.5s forwards step-start; 
// 			// animation-timing-function: ease-in;
// 		}

// 		.pageLoaded &:after{
// 			// content: '';

// 			// height: 100%;
// 			// width: 100%;
// 			// margin: (-138px - 15px) 0 0 -138px;

// 			@media @mobile-max {
// 				// height: 50/vw;
// 				// width: 50v/w;
// 				// margin: -25% 0 0 -25%;
// 				// margin-top: ~'calc((-25vw - 15px))';
// 				// margin-left: -25vw;

// 				// transform: translate(-50% -50%);
// 			}


// 		}
// 	}

// 	.logo_type{
// 		font-family: ostrich_sans_roundedmedium;
// 		letter-spacing: 2px;
// 		position: absolute;
// 		font-size: 25px;
// 		bottom: 50%;
// 		width: 100%;
// 		text-align: center;
// 		transform: translateY(175px);

// 		span{
// 			// color: #000;
// 			padding: 20px;
// 			font-size: 40px;
// 			line-height: 80px;
// 			// background-color: white;

// 			@media @mobile-max {
// 				padding: 20px 0;
// 				font-size: 30px;
// 				line-height: 30px;
// 			}
// 		}
// 	}

// 	.logo_arrow{
// 		position: absolute;
// 		left: 50%;
// 		height: 50px;
// 		bottom: 10px;
// 		width: 50px;
// 		margin-left: -25px;
// 		background-image: url(../img/arrow.png);
// 		background-position: center;
// 		background-repeat: no-repeat;
// 		cursor: pointer;
// 		background-size: 25px 25px;
// 	}
// }

// @keyframes full_flex {
// 	0.0000%  { background-position: 0 0.0000%; }
// 	6.6666%  { background-position: 0 6.6666%; }
// 	13.3333% { background-position: 0 13.3333%; }
// 	20.0000% { background-position: 0 20.0000%; }
// 	26.6666% { background-position: 0 26.6666%; }
// 	33.3333% { background-position: 0 33.3333%; }
// 	40.0000% { background-position: 0 40.0000%; }
// 	46.6666% { background-position: 0 46.6666%; }
// 	53.3333% { background-position: 0 53.3333%; }
// 	60.0000% { background-position: 0 60.0000%; }
// 	66.6666% { background-position: 0 66.6666%; }
// 	73.3333% { background-position: 0 73.3333%; }
// 	80.0000% { background-position: 0 80.0000%; }
// 	86.6666% { background-position: 0 86.6666%; }
// 	93.3333% { background-position: 0 93.3333%; }
// 	100.000% { background-position: 0 100.000%; }


// 	// 0.0000% { background-position: 0 0.0000%; }
// 	// 0.3333% { background-position: 0 0.3333%; }
// 	// 0.6666% { background-position: 0 0.6666%; }
// 	// 10.000% { background-position: 0 10.000%; }
// 	// 13.333% { background-position: 0 13.333%; }
// 	// 16.666% { background-position: 0 16.666%; }
// 	// 20.000% { background-position: 0 20.000%; }
// 	// 23.333% { background-position: 0 23.333%; }
// 	// 26.666% { background-position: 0 26.666%; }
// 	// 30.000% { background-position: 0 30.000%; }
// 	// 33.333% { background-position: 0 33.333%; }
// 	// 36.666% { background-position: 0 36.666%; }
// 	// 40.000% { background-position: 0 40.000%; }
// 	// 43.333% { background-position: 0 43.333%; }
// 	// 46.666% { background-position: 0 46.666%; }
// 	// 50.000% { background-position: 0 50.000%; }
// 	// 0% { background-position: 0 0; }

// 	// 03.125% { background-position: 0 03.125%; }
// 	// 06.25%  { background-position: 0 06.25%; }
// 	// 09.375% { background-position: 0 09.375%; }
// 	// 12.5%   { background-position: 0 12.5%; }
// 	// 15.625% { background-position: 0 15.625%; }
// 	// 18.75%  { background-position: 0 18.75%; }
// 	// 21.875% { background-position: 0 21.875%; }
// 	// 25.000% { background-position: 0 25.000%; }
// 	// 28.125% { background-position: 0 28.125%; }
// 	// 31.25%  { background-position: 0 31.25%; }
// 	// 34.375% { background-position: 0 34.375%; }
// 	// 37.5%   { background-position: 0 37.5%; }
// 	// 40.625% { background-position: 0 40.625%; }
// 	// 43.75%  { background-position: 0 43.75%; }
// 	// 46.875% { background-position: 0 46.875%; }

// 	// 50% { background-position: 0 50.000%; }


// 	// main.js? [sm]:71 0.03125
// 	// main.js? [sm]:71 0.0625
// 	// main.js? [sm]:71 0.09375
// 	// main.js? [sm]:71 0.125
// 	// main.js? [sm]:71 0.15625
// 	// main.js? [sm]:71 0.1875
// 	// main.js? [sm]:71 0.21875
// 	// main.js? [sm]:71 0.25
// 	// main.js? [sm]:71 0.28125
// 	// main.js? [sm]:71 0.3125
// 	// main.js? [sm]:71 0.34375
// 	// main.js? [sm]:71 0.375
// 	// main.js? [sm]:71 0.40625
// 	// main.js? [sm]:71 0.4375
// 	// main.js? [sm]:71 0.46875

// 	// 100%
// }




















