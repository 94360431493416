.main_item_interior.first{
	// height: 100%;

	@media @mobile-min{
		// display: table;
	}

	.first_interior{
		// padding-left: @margin * 2;
		// padding-right: @margin * 2;
		overflow: scroll;
		// overflow: hidden;
		text-align: center;
		position: relative;
		height: 100vh;
		z-index: 1;
		-webkit-overflow-scrolling: touch;

		padding-top: 70px;
		// height: ~'calc(100vh - 70px)';

		@media @mobile-min{
			overflow: hidden;
			// display: table-cell;
			// vertical-align: middle;

			display: flex;
			flex-flow: column;
			// padding-top: 70px;
			// padding-top: 
		}

		.bwm_logo_large{
			position: relative;
			// top: 50%;
			// left: 50%;
			// height: 300px;
			// height: 202px;
			// max-width: 300px;
			height: 35vh;
			// width: auto;
			// margin: @margin auto;
			width: 100%;
			// width: 100%;
			// margin: @margin auto @margin * 3;
			// margin: -150px 0 0 -150px;
			// background-image: url(../img/bwm_logo_01.png);
			// background-size: auto 70%;
			// background-position: center;
			// background-repeat: no-repeat;

			z-index: 2;

			@media @mobile-min{
				margin: 0;
				max-width: none;
				height: 202px;
				background-size: 300px auto;
				flex: 1;
			}

			.bwm_logo_large_img {
				display: block;
				height: 70%;
				width: auto;
				top: 15%;
				margin: 0 auto;
				position: relative;

				@media @mobile-min {
					height: 202px;
					position: absolute;
					top: 50%;
					left: 50%;
					margin-top: -101px;
					transform: translateX(-50%);
				}
			}

			// &:before{
			// 	content: '';
			// 	padding-bottom: 66.6667%;
			// 	display: block;

			// 	@media @mobile-min{
			// 		display: none;	
			// 	}
			// }
		}

		.first_text{
			// background-color: rgba(255,255,255,.8);
			width: 100%;
			position: relative;
			// color: white;
			font-family: sourceserifpro-regular;

			text-align: center;
			line-height: 25px;
			font-size: 15px;
			z-index: 2;
			background-color: white;

			// padding: 35px;
			padding: 10px;
			width: ~'calc(100% - 70px)';
			left: 35px;

			@media @mobile-min{
				// padding: @margin;
				// float: left;
				background-color: transparent;
				line-height: 27px;
				font-size: 17px;
				position: static;
				left: auto;
				width: 100%;
				padding: 0;

				align-self: flex-end;
			}

			h1{
				// margin-top: 10px;
				margin-top: 30px;
				color: @color-brand;
				font-family: abrilfatface-regular;

				font-size: 23px;

				@media @mobile-min{
					font-size: 32px;							
				}
			}

			.first_text_stripe{
				// padding: @margin @margin * 2;
				// padding: @margin 35px;
				width: 100%;
				background-color: white;
			}

			.first_text_interior{				
				margin: 0 auto;
				max-width: 1024px;
				overflow: hidden;

				@media @mobile-min{
					padding: 0 67px @margin;
				}
			}
		}
	}
}