.work{
  // display: flex;
	// min-height: auto;

	.work_item{
		display: block;
		float: left;
		position: relative;
		padding: 20px 20px 0;
		width: 33.3333%;
		margin-bottom: 60px;
		text-decoration: none !important;

		&:hover{
			
			background-color: white;

			.work_item_title{
				color: #3F00FF !important;
			}

		}

		&:before{
			content: '';
			position: absolute;
			background-color: white;

		}

		@media @tablet-max {
			width: 50%;
		}

		@media @mobile-max {
			width: 100%;
		}

		.work_item_interior{

			.work_item_image{

				background-size: cover;
				background-position: center;
				background-color: white;
				filter: grayscale(1) brightness(1.1);

				&:before{
					content: '';
					padding-bottom: 50%;
					display: block;

					@media @mobile-max {
						padding-bottom: 100%;
					}
				}
			}

			.work_item_title{
				// margin-top: 20px;
				border-bottom: 1px solid white;
				padding: 20px 0;

				font-family: ostrich_sans_roundedmedium;
				// line-height: 40px;
				font-size: 20px;
				letter-spacing: 2px;
				color: white;
			}
		}
	}

	.wii01{
		background-image: url(../img/mine_01/bm.png);
	}
	.wii02{
		background-image: url(../img/mine_01/bs.png);
	}
	.wii03{
		background-image: url(../img/mine_01/ew.png);
	}
	.wii04{
		background-image: url(../img/mine_01/ng.png);
	}
	.wii05{
		background-image: url(../img/mine_01/lb.png);
	}
}

