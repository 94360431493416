.fourohfour {
	position: absolute;
	top: 0;
	left: 0;
	height: 100vh;
	width: 100vw;
	background-image: url(../img/bg_00.jpg);
	background-size: cover;
	background-position: center;
	display: table;

	color: @color-copy;
	font-family: sourceserifpro-regular; 
	font-weight: 400;
	font-size: @base-font-size;
	line-height: @base-line-height;	

	.fourohfour_content {
		// position: absolute;
		// top: 0;
		// left: 0;
		// width: 100%;
		// height: 100%;
		display: table-cell;
		vertical-align: middle;
		text-align: center;
		
		.fourohfour_content_interior {
			background-color: white;
			padding: @margin;			
			display: inline-block;

			h1 { 
				margin: 0;
				font-family: abrilfatface-regular;
				font-size: 35px;
				color: @color-brand;
			}

			p {
				font-size: 20px;
				line-height: 27px;
				margin-top: @margin;

				a {
					color: @color-brand;
					font-family: sourceserifpro-bold;

					display: inline-block;
					padding: 0 2px;
					border-bottom: 1px @color-brand dotted;

					&:hover {
						text-decoration: none;
						border-color: transparent;
						color: white;
						background-color: @color-brand;
					}
				}
			}
		}
	}
}