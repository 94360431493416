/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on October 4, 2017 */



// @font-face {
//     font-family: 'robotoBlack';
//     src: url('../type/Roboto-Black-webfont.woff') format('woff');
//     font-weight: normal;
//     font-style: normal;

// }



// @font-face {
//     font-family: 'robotoBlackItalic';
//     src: url('../type/Roboto-BlackItalic-webfont.woff') format('woff');
//     font-weight: normal;
//     font-style: normal;

// }


// @font-face {
//     font-family: 'robotoBold';
//     src: url('../type/Roboto-Bold-webfont.woff') format('woff');
//     font-weight: normal;
//     font-style: normal;

// }



// @font-face {
//     font-family: 'robotoLight';
//     src: url('../type/Roboto-Light-webfont.woff') format('woff');
//     font-weight: normal;
//     font-style: normal;

// }



// @font-face {
//     font-family: 'robotoLightItalic';
//     src: url('../type/Roboto-LightItalic-webfont.woff') format('woff');
//     font-weight: normal;
//     font-style: normal;

// }



// @font-face {
//     font-family: 'robotoRegular';
//     src: url('../type/Roboto-Regular-webfont.woff') format('woff');
//     font-weight: normal;
//     font-style: normal;

// }



// @import url("//hello.myfonts.net/count/2760f4");
// @import url("//hello.myfonts.net/count/276103");
  
// @font-face {
//     font-family: 'u47';
//     src: url('../type/u47/2760F4_0_0.eot');
//     src: url('../type/u47/2760F4_0_0.eot?#iefix') format('embedded-opentype'),
//          url('../type/u47/2760F4_0_0.woff') format('woff'),
//          url('../type/u47/2760F4_0_0.ttf') format('truetype'),
//          url('../type/u47/2760F4_0_0.svg#wf') format('svg');
// }
// @font-face {
//     font-family: 'u67';
//     src: url('../type/u67/276103_1_0.eot');
//     src: url('../type/u67/276103_1_0.eot?#iefix') format('embedded-opentype'),
//          url('../type/u67/276103_1_0.woff') format('woff'),
//          url('../type/u67/276103_1_0.ttf') format('truetype'),
//          url('../type/u67/276103_1_0.svg#wf') format('svg');
// }



/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on October 19, 2017 */


// RobotoCondensed-Regular-webfont.woff
// abrilfatface-regular-webfont.woff
// sourceserifpro-bold-webfont.woff
// sourceserifpro-regular-webfont.woff


@font-face {
    font-family: 'RobotoCondensed-Regular';
    src: url('../fonts/RobotoCondensed-Regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'abrilfatface-regular';
    src: url('../fonts/abrilfatface-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'sourceserifpro-bold';
    src: url('../fonts/sourceserifpro-bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'sourceserifpro-regular';
    src: url('../fonts/sourceserifpro-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}


