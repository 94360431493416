body:not([data-slide="0"]) .nav_menu{
	// display: block;
	// opacity: 1;
	// visibility: visible;
}

.nav_menu{
	position: fixed;
	height: 50px;
	width: 50px;
	right: @margin;
	top: @margin;
	// background-color: white;
	z-index: 11;
	// display: none;

	background-image: url(../img/icon_menu.png);
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	transition: all .5s ease;
	// opacity: 0;
	// visibility: hidden;

	.showNav &{
		// background-color: black;
		background-image: url(../img/icon_close.png);

	}

	@media @tablet-min{
		display: none;
	}
}


.navigation{
	display: block;
	position: fixed;
	// top: @height_space_desktop;
	left: 0;
	top: 0;
	width: 100vw;
	background-color: white;
	// width: @width_space_desktop;
	// height: @height_box_desktop;
	z-index: 1;
	height: 100vh;
	// transform: trals
	transform: translateY(-100%);
	transition: all .5s ease;
	// display: none;
	overflow: scroll;


  @media @tablet-min{
		// transform: translateY(-70px);
		transform: translateY(0);
		height: 70px;
		overflow: visible;
  }

  .showNav &{
		transform: translateY(0);
  }

	.slick-dots{
		width: 100%;
		max-width: 1024px;
		margin: 0 auto;
		padding: 0;
		list-style: none;
		-webkit-overflow-scrolling: touch;
		display: block !important;

		// position: fixed;
		// top: 0;
		// left: 0;
		// width: 100%;
		// height: 100%;
		
		@media @tablet-min{
			// overflow: auto;
			display: flex !important;
		  flex-flow: row;
		  justify-content: space-around;
		}

		.nav_logo{
			height: 50px;
			width: 80px;
			margin: @margin auto;
			position: relative;
			// float: left;

			background-image: url(../img/bwm_logo_04.png);
			background-size: contain;
			background-position: center;
			background-repeat: no-repeat;

			@media @tablet-min{
				margin: 10px 0 0 0;
				position: relative;
				float: left;
			}

		}

		li{
			width: 100%;
			display: block;
			text-align: center;
			overflow: hidden;
			cursor: pointer;

			@media @tablet-min{
				width: auto;
				text-align: auto;
				display: inline-block;
			}

			&.slick-active span{
				border-bottom: 1px @color-brand solid;
			}

			span{
				// padding: 
				text-transform: uppercase;
				line-height: 70px;
				font-family: RobotoCondensed-Regular;
				color: @color-brand;
			}
		}
		
	}

	// display: none;

	// @media @mobile-max{
	// 	padding: 20px 0;

	// 	top: @height_space_mobile;
	// 	width: @width_space_mobile;
	// 	height: @height_box_mobile;
	// }

	.nav_point{
		// padding: 0 30px 0 0;
		position: relative;
		float: left;

		background-color: white;
		color: black;

		padding: @margin @margin / 2;
		// padding: @margin 0;
		position: relative;
		cursor: pointer;

		@media screen and (max-width: 450px){
			float: none;
			padding: @margin 0;

			.showing &{
				float: left;
			}
		}

		&:hover{
			background-color: black;

			@media @mobile-min{
				color: white;
			}
		}

		@media @mobile-min{
			padding: @margin;
		}

		@media @mobile-max{
			color: transparent;
			width: 60px;
			height: 60px;

			&:before{
				content: '';
				height: 30px;
				width: 100%;
				position: absolute;
				right: 0;
				top: 50%;
				transform: translateY(-50%);
				// top: 
				// top: -15px;
				
				background-size: contain;
				background-position: center;
				background-repeat: no-repeat;
			}

			&.nav_point_about:before{
				background-image: url(../img/icon01b.png);
			}
			&.nav_point_about:hover:before{
				background-image: url(../img/icon01w.png);
			}
			
			&.nav_point_work:before{
				background-image: url(../img/icon02b.png);
			}			
			&.nav_point_work:hover:before{
				background-image: url(../img/icon02w.png);
			}
			
			&.nav_point_contact:before{
				background-image: url(../img/icon03b.png);
			}			
			&.nav_point_contact:hover:before{
				background-image: url(../img/icon03w.png);
			}

		}

		p{
			font-family: rubikregular;
			text-align: right;
			margin: 0;

			@media @mobile-max{
				display: none;
			}
		}
	}
}





		// &:after{
		// 	content: '';
		// 	position: absolute;
		// 	top: 9px;
		// 	height: 1px;
		// 	width: 20px;
		// 	right: 5px;
		// 	background-color: #aaa;
		// 	transition: all .2s ease;
		// }

		// &.selected:after{
		// 	width: 100px;

		// 	@media @desktop-max{
		// 		content: '';
		// 		width: auto;
		// 		right: 5px;
		// 		top: 0;
		// 		left: 5px;
		// 	}
		// }

		// @media @tablet-max{
		// 	&:after{
		// 		content: '';
		// 	}
		// }