// .hello{
// 	margin-top: 20px;

// 	h1{
// 		font-family: ostrich_sans_light;
// 		font-weight: 400;
// 		line-height: 8vh;
// 		// font-size: 6vh;
// 		font-size: 12vh;

// 		b{			
// 			font-family: ostrich_sans_roundedmedium;
// 			font-weight: 400;
// 		}
// 	}	

// 	h2{
// 		font-family: ostrich_sans_light;
// 		font-weight: 400;

// 		line-height: 5vh;
// 		font-size: 3vh;

// 	}

.hello{
	// margin-top: 20px;
	// display: none;



	position: fixed;
	top: 0;
	left: 0;
	height: 100vh;
	width: 100vw;
	opacity: 0;
	visibility: hidden;
	transform: scale(1.2);
	transition: all .5s ease;
	z-index: 1;
	background-color: white;
	overflow: scroll;

	&.showing{
		opacity: 1;
		visibility: visible;
		transform: scale(1);
	}

	.hello_close{
		position: absolute;
		top: @margin;
		right: @margin;
		height: 60px;
		width: 60px;
		background: black;
		border-radius: 50%;
		cursor: pointer;

		&:before,
		&:after{
			content: '';
			position: absolute;
			top: 50%;
			left: 50%;
			height: 1px;
			width: 30px;
			margin: -1px 0 0 -15px;
			background: white;
			z-index: 1;
		}

		&:before{
			transform: rotate(-45deg);
		}

		&:after{
			transform: rotate(45deg);
		}

	}

	.hello_interior{


		max-width: 1024px;
		width: 100%;
		margin: 0 auto;
		padding: @margin * 4 @margin @margin * 2;
			color: black;
	}

	h1{
		font-family: rubikregular;
		font-weight: 400;
		line-height: 8vh;
		font-size: 6vh;

		b{			
			font-family: rubikmedium;
			font-weight: 400;
		}
	}	

	h2{
		font-family: rubikregular;
		font-weight: 400;

		line-height: 5vh;
		font-size: 3vh;

	}

	.hello_more{
		margin: 50px 0;

		span{
			border-bottom: 1px dotted black;
			cursor: pointer;
		}

		.showingHello &{
			display: none;
		}
	}

	.hello_text{
		// display: none;
		margin-top: 50px;

		flex-flow: row wrap;
		justify-content: space-around;

		// visibility: hidden;
		// opacity: 0;

		transition: all .2s ease;

		// .showingHello &{
			display: flex;
			// display: block;
			// visibility: visible;
			// opacity: 1;
		// }

		p{
			display: block;
			width: 33.3333%;
			position: relative;
			float: left;
			padding: 0 20px;

			font-size: 13px;
			line-height: 27px;
			// text-rendering: geometricPrecision;

			border-left: 1px solid rgba(0, 0, 0, .2);

			@media @mobile-max {
				width: 100%;
				border-left: 0;
				border-bottom: 1px dotted black;
				margin-bottom: 20px;
				// margin-bottom: 10px;

				padding: 0 0 20px;

			}

			// margin: 
		}
	}
}












