.main{
	display: block;
	position: absolute;
	height: 100vh;
	width: 100vw;
	top: 0;
	left: 0;
	z-index: 0;
	transition: all .5s ease;
	// padding-top: 0;

	.showNav &{
		// padding-top: 70px;
	}

	// background-color: #3F00FF;


	.slick-arrow{
		position: absolute;
		bottom: 50%;
		// bottom: @margin;
		// height: 28px;
		// width: 28px;
		height: 64px;
		// height: 27px;
		width: 27px;
		margin-bottom: -14px;
		// background-color: white;
		z-index: 10;
		border-radius: 50%;	
		// background-size: auto 60%;
		background-size: auto 30%;
		background-position: center;
		background-repeat: no-repeat;
		// border: 1px solid black;
		cursor: pointer;

		// &:before {
		// 	content: '';
		// 	display: block;
		// 	height: 26px;
		// 	width: 26px;
		// 	position: absolute;
		// 	top: 50%;
		// 	left: 50%;
		// 	border-radius: 50%;
		// 	z-index: -1;
		// 	margin: -13px 0 0 -13px;
		// 	background-color: white;
		// }

		@media @tablet-min{
			background-color: transparent;
			background-size: auto 70%;
			// height: 40px;
			// width: 40px;
			// margin-top: -20px;
			margin-bottom: -64px - @margin;
			height: 64px;
			width: 27px;
		}
	}

	.slick-prev{
		left: 5px;
		background-image: url(../img/arrow_left_2.png);

		@media @mobile-max {
			[data-slide="0"] & {
				background-image: url(../img/arrow_left_2_w.png);
			}
		}
		// background-position: 12px center;

		@media @tablet-min{
			// left: @margin;		
		}
	}
	.slick-next{
		right: 5px;
		background-image: url(../img/arrow_right_2.png);

		@media @mobile-max {
			[data-slide="0"] & {
				background-image: url(../img/arrow_right_2_w.png);
			}
		}
		// background-position: 14px center;

		@media @tablet-min{
			// right: @margin;		
		}
	}

	.slick-dots{
		// position: absolute;
		position: relative;
		// top: ~'calc(100% - 50px)';
		// bottom: @margin;
		max-width: 1024px;
		// top: 10px;
		margin: 10px auto 0;
		padding: 0;
		list-style: none;
		text-align: center;
		width: 100%;
		z-index: 9;
		opacity: 1;
		visibility: visible;
		transition: all .5s ease; 

		.showing &{
			// display: block;
			opacity: 0;
			visibility: hidden;
		}

		li{
			position: relative;
			// float: left;
			display: inline-block;
			width: 20px;
			height: 50px;

			@media @mobile-min{
				width: 50px;
			}

			&.slick-active button:before{
				opacity: 1;
				// content: '';
				// position: absolute;
				// top: 50%;
				// height: 14px;
				// width: 14px;
				// left: 50%;
				// z-index: 1;

				// margin: -7px 0 0 -7px;
				// background-color: #999;
				// border-radius: 50%;

			}

			button{
				position: relative;
				appearance: none;
				border: 0;
				background: transparent;
				overflow: hidden;
				margin: 0;
				// padding: 0 @margin;
				padding: 0;
				height: 100%;
				width: 100%;
				text-indent: 1000%;

				&:hover, &:focus{
					outline: none;
				}

				&:before{
					content:'';
					position: absolute;
					top: 50%;
					height: 10px;
					width: 10px;
					left: 50%;
					z-index: 2;

					margin: -5px 0 0 -5px;
					background-color: white;
					border-radius: 50%;
					opacity: .7;
				}
			}
		}
	}

	.main_item_interior{

		height: 100vh;
		width: 100vw;
		position: relative;
		float: left;
		overflow: scroll;
		-webkit-overflow-scrolling: touch;
		outline: none !important;
		display: flex;
		flex-flow: column;
		// perspective: 300px;
		// padding-top: 70px;

		// &.first{
		// 	padding-top: 0;
		// }

		&.showing{
			.main_item_button{
				opacity: 0;
				visibility: hidden;
			}

			.main_item_formal{
				// top: -1
				@media @desktop-max{
					// transform: translateY(~'calc(-100% - @{margin})');
				}
				// top: ~'calc(((100vw - 1024px) / 2) + (@{margin} * 3))';
				// max-width: ~'calc((100vw - 1024px) / 2)';

				@media @desktop-min{
					// top: ~'calc(((100vw - 1024px) / 2) + (@{margin} * 3))';
					// max-width: ~'calc((100vw - 1024px) / 2)';
				}

				// top: -1
				@media @desktop-max{

					opacity: 0;
					visibility: hidden;
					// transform: translateX(~'calc(-100% - @{margin})');
				}
				// top: ~'calc(((100vw - 1024px) / 2) + (@{margin} * 3))';
				// max-width: ~'calc((100vw - 1024px) / 2)';

				@media @desktop-min{
					top: ~'calc(((100vw - 1024px) / 2) + (@{margin} * 3))';
					max-width: ~'calc((100vw - 1024px) / 2)';
				}
			}

		}

		.main_item_image{ 
			position: absolute;
			width: 100%;
			height: 35vh;
			top: 0;
			left: 0;
			// padding-top: 70px;
			// bottom: 0;
			// right: 0;
			background-size: cover;
			background-repeat: no-repeat;
			background-position-x: center;
			background-position-y: center;


			@media @tablet-min{
				position: static;
				flex: 1;
				// margin-top: 70px;
			}

			@media @desktop-min{
				// height: 50vh;
			}

			&.mii00{			
				position: absolute;	
				height: 100%;
				top: 0;
				margin-top: 0px !important;
				z-index: 1;


				@media @mobile-min{
					background-position-y: ~'calc(50% + 100px)';
					// top: 70px;
				}

				@media @tablet-min{
					background-position-y: ~'calc(50% + 250px)';
				}
				// top: 5px;
				// left: 5px;
				// height: ~'calc(100vh - (10px) )';
				// width: ~'calc(100vw - (10px) )';
			}

	    // transform: translateZ(-300px) scale(2);
			// background-attachment: fixed;
		}



		.item_info{

		}

		.market_link{
			display: inline-block;
			// display: b
			// position: relative;
			// float: left;
			// width: 100%;
			// bottom: 50px + (@margin * 2);
			// position: absolute;
			// left: 50%;
			// transform: translateX(-50%);
			// border: 5px solid black;
			// font-family: robotoLightItalic;
			// text-transform: uppercase;
			// margin-top: @margin;
			font-size: 20px;
			// line-height: 45px;
			color: white !important;
			background-color: @color-brand;
			cursor: pointer;
			text-decoration: none;
			padding: @margin;
			font-style: italic;
			outline: 0 !important;
			font-family: sourceserifpro-regular;

			// &:focus{
			// }
		}
	}

	.mii00{
		background-image: url(../img/bg_00.jpg);
	}
	.mii01{
		background-image: url(../img/bg_01.jpg);
	}
	.mii02{
		background-image: url(../img/bg_02.jpg);
	}
	.mii03{
		background-image: url(../img/bg_03.jpg);
	}
	.mii04{
		background-image: url(../img/bg_04.jpg);
	}
	.mii05{
		background-image: url(../img/bg_05.jpg);
		background-position-y: top !important;
	}
	.mii06{
		background-image: url(../img/bg_06.jpg);
	}


}